import React from "react";
import {
  Button,
  Card,
  Box,
  FormControl,
  TextField,
  Typography,
  Grid,
  FormGroup,
} from "@mui/material";
import { useState } from "react";
import { API } from "aws-amplify";
import { createCustomerMessage } from "../graphql/mutations";
import {
  validateEmail,
  validatePhone,
  validateName,
  validateMessage,
} from "../services/FormValidation";
import Icon from "@mdi/react";
import { mdiFacebookMessenger as MessengerIcon } from "@mdi/js";

function ContactPage({ closeContactForm, openSuccessMessage }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const submitContactForm = () => {
    const customerMessage = {
      name: name,
      email: email,
      phone: phone ? phone : "(No phone provided)",
      subject: subject ? subject : "(No Subject)",
      message: message,
    };
    setNameError(validateName(name));
    setEmailError(validateEmail(email));
    if (phone !== "") setPhoneError(validatePhone(phone));
    setMessageError(validateMessage(message));
    if (
      !validateName(name) &&
      !validateEmail(email) &&
      (phone === "" || !validatePhone(phone)) &&
      !validateMessage(message)
    ) {
      try {
        API.graphql({
          query: createCustomerMessage,
          variables: {
            input: customerMessage,
          },
        });
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
        openSuccessMessage(true);
        closeContactForm();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const openMessenger = () => {
    window.open("http://m.me/haasfab", "_blank");
  };

  return (
    <div className="container" style={{}}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={3}>
          <Card sx={{ padding: 2 }}>
            <FormControl>
              <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Contact Us</Typography>
                <Grid container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 1,
                  }}
                >
                  <Typography>Complete this form or message us on</Typography>
                  <Button
                    onClick={openMessenger}
                    sx={{ textTransform: "none" }}
                    endIcon={
                      <Icon path={MessengerIcon} size={1} color="#006AFF" />
                    }
                  >
                    <Typography>Facebook</Typography>
                  </Button>
                </Grid>
              </Box>

              <TextField
                id="name"
                variant="standard"
                label="Full Name"
                type="text"
                size="small"
                value={name}
                error={nameError}
                helperText={nameError ? "Enter a valid name" : null}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError(false);
                }}
                sx={{ margin: 1 }}
                required
              />
              <FormGroup row sx={{ display: "flex" }}>
                <TextField
                  id="email"
                  variant="standard"
                  label="Email"
                  type="email"
                  size="small"
                  value={email}
                  error={emailError}
                  helperText={emailError ? "Enter a valid email" : null}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                  sx={{ margin: 1 }}
                  required
                />

                <TextField
                  id="phone"
                  variant="standard"
                  label="Phone Number"
                  type="number"
                  size="small"
                  value={phone}
                  error={phoneError}
                  helperText={phoneError ? "Enter a valid phone number" : null}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setPhoneError(false);
                  }}
                  sx={{ margin: 1 }}
                />
              </FormGroup>

              <FormGroup sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="subject"
                  variant="standard"
                  label="Subject"
                  type="text"
                  size="small"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  sx={{ margin: 1 }}
                />
                <TextField
                  id="Message"
                  variant="standard"
                  label="Message"
                  type="text"
                  multiline
                  value={message}
                  error={messageError}
                  helperText={
                    messageError
                      ? `Message must be longer than ${message.length} characters`
                      : null
                  }
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setMessageError(false);
                  }}
                  sx={{ margin: 1 }}
                  inputProps={{ maxLength: 500 }}
                  required
                />
              </FormGroup>

              <Button variant="contained" onClick={submitContactForm}>
                Submit
              </Button>
            </FormControl>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactPage;
