import "./App.css";
import { Route } from "react-router-dom";
import { useState } from "react";

import Amplify from "aws-amplify";
import config from "./aws-exports";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Routes from "./Routes";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Snackbar, Alert } from "@mui/material";
import { theme } from "./services/Theme";

Amplify.configure(config);

function App() {
  const [successMessage, setSuccessMessage] = useState(false);
  const openSuccessMessage = () => {
    setSuccessMessage(true);
  };
  const handleMessageClose = () => {
    setSuccessMessage(false);
  };

  const [instaFeed, setInstaFeed] = useState([]);
  const updateInstaFeed = (data) => {
    setInstaFeed(data);
  }
  return (
    <ThemeProvider theme={theme}>
      <Box className="App" backgroundColor={theme.palette.background.default}>
        <Header openSuccessMessage={openSuccessMessage} />
        <Box
          sx={{
            marginTop: {xs: "64px", sm: "94px"},
            display: "flex",
            
            maxWidth: "1200px",
            minHeight: "95vh",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Routes openSuccessMessage={openSuccessMessage} updateInstaFeed={updateInstaFeed} instaFeed={instaFeed} />

        </Box>

        <Footer/>

        <Snackbar
          open={successMessage}
          onClose={handleMessageClose}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleMessageClose} severity="success">
            Contact Form Sent! We'll get back to you ASAP
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
}

export default App;
