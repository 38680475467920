import React, { useEffect, useState } from "react";
import { Box, Modal, Grid } from "@mui/material";

import ServiceCard from "../components/ServiceCard";
import ContactPage from "./ContactPage";

import Welding from "../images/Welding.jpg";
import PowderCoating from "../images/PC.jpg";
import PlasmaCutting from "../images/PlasmaCutting.png";
import Cerakote from "../images/Cerakote.jpg";
import Fabrication from "../images/Fabrication.jpg";
import CustomSigns from "../images/CustomSigns.jpg";
import Printing from "../images/Printing.png";
import Modeling from "../images/CADModeling.png";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { theme } from "../services/Theme";
import useWindowDimensions from "../services/useWindowDimensions";

function ServicesPage({ openSuccessMessage }) {
  const [contactUsState, setContactUsState] = useState(false);
  const [tapHere, setTapHere] = useState(false);
  const [counter, setCounter] = useState(0);
  const [openDescription, setOpenDescription] = useState(false);
  const { height, width } = useWindowDimensions();

  const toggleContactForm = () => {
    setContactUsState(!contactUsState);
  };
  const closeContactForm = () => {
    setContactUsState(false);
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      sessionStorage.setItem("DemoOver", "false");
    };
    return () => {
      //window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("DemoOver") !== "true") {
      var interval = setInterval(() => {
        setCounter((counter) => counter + 1);
      }, 300);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (counter < 11 && width < 600 && counter !== 0) {
      setTapHere(!tapHere);
    } else if (counter === 11) {
      setOpenDescription(true);
    } else if (counter === 12) {
      sessionStorage.setItem("DemoOver", "true");
    }
  }, [counter]);

  return (
    <Grid
      container
      spacing={{ sm: 3, xs: 0 }}
      style={{
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <Grid item sm={6} xs={12}>
        <TouchAppIcon
          fontSize="large"
          sx={{
            position: "absolute",
            top: 400,
            right: 40,
            color: theme.palette.text.light,
            opacity: tapHere ? 100 : 0,
            transition: "ease-in-out 0.1s",
          }}
        />

        <ServiceCard
          openDescription={openDescription}
          serviceImage={PowderCoating}
          serviceTitle="Powder Coating"
          serviceDescription={
            <div>
              We provide full service powder coating including: <br /> <br /> -
              Chemical Stripping <br /> - Sandblasting <br /> -
              Primers/Phosphate Coating <br />- Multi-Stage Finishes
              <br />
            </div>
          }
          openContactForm={toggleContactForm}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <ServiceCard
          serviceImage={Welding}
          serviceTitle="Welding Services"
          serviceDescription="We provide TIG, MIG, and Stick welding for all of your Mild Steel, Stainless Steel, and Aluminum needs."
          openContactForm={toggleContactForm}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <ServiceCard
          serviceImage={PlasmaCutting}
          serviceTitle="CNC Plasma Cutting"
          serviceDescription='Our 48"x33" plasma table can make clean cuts on up to 3/4" steel and 1/2" aluminum. We are able to make all sorts of custom brackets, signs, etc. This pairs well with our powder coating and cerakote services to make long lasting products.'
          openContactForm={toggleContactForm}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <ServiceCard
          serviceImage={Cerakote}
          serviceTitle="Cerakote Application"
          serviceDescription='Cerakote is a low thickness, extremely durable finish. Application can be as thin as 0.001" and provide superior resistance to heat, chemicals, and scratches'
          openContactForm={toggleContactForm}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <ServiceCard
          serviceImage={Fabrication}
          serviceTitle="Fabrication Services"
          serviceDescription="We can handle small to medium fabrication jobs. We primarily deal with automotive and small equipment fabrication."
          openContactForm={toggleContactForm}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ServiceCard
          serviceImage={CustomSigns}
          serviceTitle="Custom Metal Signs"
          serviceDescription="We can make single layer, multilayer, and backlit signs up to 4ft x 5ft. We recommend our powder coat or Cerakote application service for a durable finish."
          openContactForm={toggleContactForm}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ServiceCard
          serviceImage={Printing}
          serviceTitle="3D Printing"
          serviceDescription="Our 3D printing service is for those who want to test a prototype or need a one-off part made. Our printers have a build area of 400mm x 400mm x 250mm and can print PETG, PLA, TPU, ABS, ASA, and more."
          openContactForm={toggleContactForm}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ServiceCard
          serviceImage={Modeling}
          serviceTitle="CAD Modeling"
          serviceDescription="If you have a design in mind we can create CAD models from pictures or drawings. We can pair this with our 3D printing or Plasma Cutting services."
          openContactForm={toggleContactForm}
        />
      </Grid>

      <Modal open={contactUsState} onClose={toggleContactForm}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            border: "2px solid #000",
            display: "flex",
          }}
        >
          <ContactPage
            closeContactForm={closeContactForm}
            openSuccessMessage={openSuccessMessage}
          />
        </Box>
      </Modal>
    </Grid>
  );
}

export default ServicesPage;
