import React from "react";
import { Typography, Box, Card, Button, Grid, Divider } from "@mui/material";
import { theme } from "../services/Theme";
import DSM from "../images/DSM.jpg";

function AboutPage() {
  return (
    <Grid
      container
      spacing={{ sm: 3, xs: 1 }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 5,
        width: "100vw",
        maxWidth: "1200px",
      }}
    >
      <Grid item sm={6} sx={{ width: "100%" }}>
        <Box
          style={{
            backgroundImage: `url(${DSM})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: 5,
          }}
          sx={{
            textAlign: "center",
            minHeight: "300px",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        />
      </Grid>
      <Grid item sm={6}>
        <Card
          sx={{
            minHeight: "300px",
            padding: 3,
            backgroundColor: theme.palette.background.main,
          }}
        >
          <Typography
            textAlign="center"
            variant="h5"
            color={theme.palette.text.light}
          >
            About Us
          </Typography>
          <Divider />
          <Typography
            variant="h6"
            color={theme.palette.text.light}
            marginTop={1}
          >
            HaasFab is a small shop that specializes in fabrication and
            finishing. We offer powder coating, cerakote, sandblasting, chemical
            stripping, CNC plasma cutting, and welding. Contact us to get
            started on your project!
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AboutPage;
