import React from "react";
import { Routes as Switch, Route } from "react-router";

import ServicesPage from "./pages/ServicesPage";
import AboutPage from "./pages/AboutPage";
import GalleryPage from "./pages/GalleryPage";
import ContactPage from "./pages/ContactPage";
import PrivacyPolicy from "./pages/PrivacyPolicy"; 

const Routes = ({openSuccessMessage, updateInstaFeed, instaFeed }) => {
  return (
    <Switch>
      <Route
        path="/"
        element={<ServicesPage openSuccessMessage={openSuccessMessage} />}
      />
      <Route
        path="/gallery"
        element={
          <GalleryPage
            updateInstaFeed={updateInstaFeed}
            instaFeed={instaFeed}
          />
        }
      />
      <Route path="/about" element={<AboutPage />} />
      <Route
        path="/contact"
        element={<ContactPage openSuccessMessage={openSuccessMessage} />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Switch>
  );
};
export default Routes;
