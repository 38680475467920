import React, { useState } from "react";
import InstaFeeds from "../services/InstaFeeds";
import { Box, Grid, Button, Typography } from "@mui/material";
import Feed from "../services/Feed";
import { theme } from "../services/Theme";
import CloseIcon from "@mui/icons-material/Close";
import InstagramIcon from "@mui/icons-material/Instagram";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import useEventListener from "../services/event-listener";
import { ArrowLeft } from "@mui/icons-material";

function ImageModal({ modalContent, closeModal, nextImage, prevImage, contentIndex, maxIndex }) {
  const [overlay, setOverlay] = useState(true);

  const openInstagramPage = () => {
    window.open("https://www.instagram.com/haasfab_llc/", "_blank");
  };
  const toggleOverlay = () => {
    setOverlay(!overlay);
  };
  function handler({ key }) {
    if (String(key) === "ArrowLeft" && contentIndex > 0) {
      prevImage();
    } else if (String(key) === "ArrowRight" && contentIndex < maxIndex) {
      nextImage();
    } else if (String(key) === "Escape") {
      closeModal();
    }
  }

  useEventListener('keydown', handler);
  return (
    <Grid
      container
      maxWidth="800px"
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",

        bgcolor: "background.paper",
        display: "flex",
      }}
    >
      <img
        width="100%"
        height="auto"
        id={modalContent.id}
        src={modalContent.media_url}
        alt={modalContent.caption}
        onClick={toggleOverlay}
      />
      <Box
        sx={{
          position: "absolute",
          alignSelf: "flex-end",
          backgroundColor: "rgba(0,0,0,0.8)",
          width: "100%",
          transition: "ease-in-out 0.2s",
          textAlign: "center",
          opacity: overlay ? 100 : 0,
        }}
      >
        <Typography color={theme.palette.text.light}>
          {modalContent.caption}
        </Typography>
      </Box>
      <Button
        onClick={closeModal}
        sx={{
          position: "absolute",
          top: "1%",
          right: "1%",
          transition: "ease-in-out 0.2s",
          opacity: overlay ? 100 : 0,
        }}
      >
        <CloseIcon fontSize="large" />
      </Button>
      <Button
        onClick={openInstagramPage}
        startIcon={<InstagramIcon />}
        variant="contained"
        sx={{
          position: "absolute",
          top: "1%",
          left: "1%",
          transition: "ease-in-out 0.2s",
          opacity: overlay ? 100 : 0,
        }}
      >
        Visit our Instagram Page
      </Button>

      {contentIndex > 0 && (
        <Button
          onClick={prevImage}
          variant="contained"
          sx={{
            position: "absolute",
            top: "25%",
            left: "0%",
            padding: 0,
            height: '50%',
            minWidth: '20px',
            transition: "ease-in-out 0.2s",
            opacity: overlay ? 0.9 : 0,
          }}
        >
          <ChevronLeftIcon fontSize="large" />
        </Button>
      )}
      {contentIndex < maxIndex && (
        <Button
          onClick={nextImage}
          variant="contained"
          sx={{
            position: "absolute",
            top: "25%",
            right: "0%",
            padding: 0,
            height: '50%',
            minWidth: '20px',
            transition: "ease-in-out 0.2s",
            opacity: overlay ? 0.9 : 0,
          }}
        >
          <ChevronRightIcon fontSize="large" />
        </Button>
      )}
    </Grid>
  );
}

export default ImageModal;
