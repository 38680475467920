import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Button, Typography, Modal } from "@mui/material";
import Feed from "../services/Feed";
import { theme } from "../services/Theme";
import ImageModal from "../components/ImageModal";
import axios from "axios";

function GalleryPage({ updateInstaFeed, instaFeed }) {

  const token = process.env.REACT_APP_ENV_INSTA_KEY;
  const limit = 99;
  const tokenProp = useRef(token);

  useEffect(() => {
    // this is to avoid memory leaks
    const abortController = new AbortController();

    async function fetchInstagramPost() {
      var arr1 = [];
      try {
        axios
          .get(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${limit}&access_token=${token}`
          )
          .then((resp) => {
            resp.data.data.map((e, i) => {
              if (e.media_type === "IMAGE") {
                arr1.push(e);
              } else if (e.media_type === "CAROUSEL_ALBUM") {
                arr1.push(e);
              }
              
            })
            updateInstaFeed(arr1);
          });
      } catch (err) {
        console.log("error", err);
      }
    }

    // manually call the fecth function
    if (instaFeed.length === 0) fetchInstagramPost();
    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort();
    };
  }, [limit]);

  

  const [modalContent, setModalContent] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [contentIndex, setContentIndex] = useState();

  const openModal = (e) => {
    
    setModalOpen(true);
    instaFeed.map((image, i) => {
      if (image.id === e.id) {
        setContentIndex(i);
        setModalContent(image);
      }
    });
  };
  const closeModal = () => {
    setModalContent([]);
    setModalOpen(false);
    setContentIndex();
  };
  const nextImage = () => {
    setModalContent(instaFeed[contentIndex + 1]);
    setContentIndex(contentIndex + 1);
  };
  const prevImage = () => {
    setModalContent(instaFeed[contentIndex - 1]);
    setContentIndex(contentIndex - 1);
  };

  return (
    <Grid
      
      container
      spacing={{ sm: 3, xs: 1 }}
      style={{
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingTop: 5,
      }}
    >
      {instaFeed.map((feed) => (
        <Grid item xs={4} key={feed.id}>
          <Feed key={feed.id} feed={feed} openModal={openModal} />
        </Grid>
      ))}
      <Modal open={modalOpen} onClose={closeModal}>
        <ImageModal
          modalContent={modalContent}
          closeModal={closeModal}
          nextImage={nextImage}
          prevImage={prevImage}
          contentIndex={contentIndex}
          maxIndex={instaFeed.length - 1}
        />
      </Modal>
    </Grid>
  );
}

export default GalleryPage;
