import React, { useState, useEffect } from "react";
import "../App.css"
import {
  Box,
  Typography,
  Slide,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {theme} from "../services/Theme";

const HeaderLink = ({ text, path, setLinksHovered, linksHovered, setActivePathName}) => {
  //const classes = useStyles();
  const location = useLocation();
  const [hovered, setHovered] = useState(false);
  const [isActivePath, setIsActivePath] = useState(false);


  useEffect(() => {
    if (path === location.pathname) {
      setIsActivePath(true);
      setActivePathName(text);
    }
    else setIsActivePath(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const mouseEnter = (e) => {
    setHovered(true);
    setLinksHovered(true);

  };
  const mouseLeave = () => {
    setHovered(false);
    setLinksHovered(false);
  };
  return (
    <Link
      to={path}
      onClick={()=> {window.scrollTo(0,0)}}
      className="noselect"
      style={{ ...(isActivePath ? {color: theme.palette.text.secondary} : {color: 'white'}), textDecoration: "none", marginLeft: 20}}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1,  ...(isActivePath ? {color: 'secondary'} : {color: 'white'})}}
        /*className={
          isActivePath ? classes.headerLinkTextActive : classes.headerLinkText
        }*/
      >
        {text}
      </Typography>
      <Slide direction="right" in={hovered || (isActivePath && !linksHovered)}>
        <Box /*className={classes.headerLinkUnderline}*/ />
      </Slide>
    </Link>
  );
};

// const useStyles = makeStyles((theme) => ({
// headerLink: {
//   overflow: 'hidden',
// },
//   headerLinkText: {
//     width: 100,
//     color: "rgba(150, 150, 150, 1)",
//     "&:hover": {
//       color: "white",
//     },
//   },
//   headerLinkTextActive: {
//     width: 100,
//     color: "white",
//   },
//   //borderBottom: "3px solid #0592f0",
//   headerLinkUnderline: {
//     height: 3,
//     backgroundColor: "#0592f0",
//     width: "100%",
//   },
// }));

export default HeaderLink;