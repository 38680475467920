import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { theme } from "../services/Theme";
import ImageModal from "../components/ImageModal";

const Feed = (props) => {
  const { id, caption, media_type, media_url } = props.feed;
  let post;

  const [hovered, setHovered] = useState(false);

  const handleClick = (e) => {
    props.openModal(e.target);
  };
  const hover = () => {
    setHovered(true);
  };
  const notHover = () => {
    setHovered(false);
  };
  switch (media_type) {
    
    case "VIDEO":
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <Button
          onMouseEnter={hover}
          onMouseLeave={notHover}
          sx={{ padding: 0, textTransform: "none" }}
          onClick={(e) => handleClick(e)}
        >
          <img
            width="100%"
            height="auto"
            id={id}
            src={media_url}
            alt={caption}
          />
          <Box
            sx={{
              position: "absolute",
              alignSelf: "flex-end",
              backgroundColor: "rgba(0,0,0,0.8)",
              width: "100%",
              opacity: hovered ? 100 : 0,
              transition: "ease-in-out 0.2s",
              pointerEvents: "none",
            }}
          >
            <Typography color={theme.palette.text.light}>{caption}</Typography>
          </Box>
        </Button>
      );
      break;
    default:
      post = (
        <Button
          onMouseEnter={hover}
          onMouseLeave={notHover}
          sx={{ padding: 0, textTransform: "none" }}
          onClick={(e) => handleClick(e)}
        >
          <img
            width="100%"
            height="auto"
            id={id}
            src={media_url}
            alt={caption}
          />
          <Box
            sx={{
              position: "absolute",
              alignSelf: "flex-end",
              backgroundColor: "rgba(0,0,0,0.8)",
              width: "100%",
              opacity: hovered ? 100 : 0,
              transition: "ease-in-out 0.2s",
              pointerEvents: "none",
            }}
          >
            <Typography color={theme.palette.text.light}>{caption}</Typography>
          </Box>
        </Button>
      );
  }

  return <React.Fragment>{post}</React.Fragment>;
};

export default Feed;

//;
