
export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return !re.test(email);
}
export function validatePhone(phone) {
  //eslint-disable-next-line
  return !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    phone
  );
}
export function validateName(name) {
  if (name.length <= 1 || /\d/.test(name))   return true;
  
  else return false;
}
export function validateMessage(message) {
  if (message.length <= 10) return true;
  else return false;
}