
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#313131',
      contrastText: '#e2e2e2',
    },
    secondary: {
      main: '#003471',
      dark: '#dadada',
    },
    background: {
      main: '#313131',
      default: '#141414',
      paper: '#eaeaea',
    },
    text: {
      primary: '#252525',
      secondary: 'rgba(80,80,80,1)',
      light: '#dadada',
      disabled: 'rgba(80,80,80,0.5)',
      hint: 'rgba(74,74,74,0.5)',
    },
    divider: 'rgba(200, 200, 200, 0.2)',
  },
});