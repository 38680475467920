import "../App.css";
import { Typography, Box, Divider, Button } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { theme } from "../services/Theme";
import useWindowDimensions from "../services/useWindowDimensions";

function ServiceCard({
  serviceImage,
  serviceTitle,
  serviceDescription,
  openContactForm,
  openDescription,
}) {
  const [overlay, setOverlay] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (openDescription && width < 600) setOverlay(true);
  }, [openDescription]);

  const handleClick = () => {
    setOverlay(!overlay);
  };
  const hover = () => {
    if (width >= 600) setOverlay(true);
    if (height > 10000) {
    }
  };
  const notHover = () => {
    if (width >= 600) setOverlay(false);
  };
  return (
    <Box
      onClick={handleClick}
      onMouseEnter={hover}
      onMouseLeave={notHover}
      style={{
        backgroundImage: `url(${serviceImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        height: 400,
        width: "100%",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(0,0,0,0.8)",
          padding: 1,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Typography color={theme.palette.text.light} variant="h5">
          {serviceTitle}
        </Typography>
        <Divider />
      </Box>

      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          height: "100%",
          flexGrow: 1,
          flexDirection: "column",
          opacity: overlay ? 100 : 0,
          backgroundColor: "rgba(0,0,0,0.8)",
          transition: "ease-in-out 0.2s",
          padding: 3,
          justifyContent: "space-between",
        }}
      >
        <Typography
          textAlign="left"
          color={theme.palette.text.light}
          variant="h6"
        >
          {serviceDescription}
        </Typography>

        <Button
          variant="contained"
          disabled={overlay ? false : true}
          color="secondary"
          component={Link}
          to="/contact"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          sx={{
            disabled: overlay ? false : true,
            textDecoration: "none",
            zIndex: 1000,
            display: { xs: "flex", sm: "none" },
          }}
        >
          Get Started
        </Button>

        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Button
            disabled={overlay ? false : true}
            variant="contained"
            color="secondary"
            onClick={openContactForm}
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ServiceCard;

/*
      
    */
