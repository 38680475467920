import React, { useState } from "react";
import {
  Button,
  AppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Drawer,
  Modal,
  Divider,
  CssBaseline,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { NavLink as Link } from "react-router-dom";
import HeaderLink from "./HeaderLink";
import { theme } from "../services/Theme";
import ContactPage from "../pages/ContactPage";
import HaasFabLogoFull from "../images/HaasFabLogoFull.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import Icon from "@mdi/react";
import { mdiFacebookMessenger as MessengerIcon } from "@mdi/js";

const Header = ({ openSuccessMessage }) => {
  //const classes = useStyles();

  const [linksHovered, setLinksHovered] = useState(false);
  //eslint-disable-next-line
  const [activePathName, setActivePathName] = useState("");
  const getLinksHovered = (state) => {
    setLinksHovered(state);
  };
  const updateActivePathName = (name) => {
    setActivePathName(name);
  };
  const [drawerState, setDrawerState] = useState(false);
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  const handleClick = (e) => {
    window.scrollTo(0,0);
    setActivePathName(e.target.outerText)
    toggleDrawer();
  }
  const [contactUsState, setContactUsState] = useState(false);
  const toggleContactForm = () => {
    setContactUsState(!contactUsState);
  };
  const closeContactForm = () => {
    setContactUsState(false);
  };

  const openInstagramPage = () => {
    window.open("https://www.instagram.com/haasfab_llc/", "_blank");
  };
  const openFacebookPage = () => {
    window.open("https://www.facebook.com/HaasFab-100925839159371", "_blank");
  };
  const openMessenger = () => {
    window.open("http://m.me/haasfab", "_blank");
  };
  const openEmailTo = () => {
    window.open("mailto:business@haasfab.com", "_self");
  };
  return (
    <Box
      sx={{ position: "fixed", width: "100vw", height: "64px", zIndex: 10000 }}
    >
      <CssBaseline />
      <AppBar
        position="static"
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <Toolbar
          sx={{
            maxWidth: 1200,
            width: "100%",
            height: "64px",
            alignItems: "center",
            display: "flex",
            paddingTop: 0.65,
            justifyContent: { xs: "space-between", sm: "space-between" },
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "white" }}           onClick={()=> {window.scrollTo(0,0)}}
>
            <img src={HaasFabLogoFull} height="56px" alt="haasfab_logo_full"/>
          </Link>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <HeaderLink
              text="Services"
              path="/"
              setLinksHovered={getLinksHovered}
              linksHovered={linksHovered}
              setActivePathName={updateActivePathName}
            />
            <HeaderLink
              text="Gallery"
              path="/gallery"
              setLinksHovered={getLinksHovered}
              linksHovered={linksHovered}
              setActivePathName={updateActivePathName}
            />
            <HeaderLink
              text="About"
              path="/about"
              setLinksHovered={getLinksHovered}
              linksHovered={linksHovered}
              setActivePathName={updateActivePathName}
            />
          </Box>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: "flex", sm: "none" } }}
            onClick={toggleDrawer}
          >
            <Menu />
          </IconButton>

          <Button
            sx={{
              justifySelf: "flex-end",
              display: { xs: "none", sm: "flex" },
            }}
            variant="contained"
            color="secondary"
            onClick={toggleContactForm}
          >
            Contact Us
          </Button>
          <Drawer
            anchor="right"
            open={drawerState}
            onClose={toggleDrawer}
            sx={{ backgroundColor: theme.palette.background.main }}
          >
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "90vw",
                height: "90%",
                display: "flex",
                padding: 10,
                flexDirection: "column",
                backgroundColor: theme.palette.background.main,
              }}
            >
              <Link
                to="/"
                className="noselect"
                style={{
                  textDecoration: "none",
                  color: activePathName === "Services" ? theme.palette.text.secondary : theme.palette.text.light,
                  marginTop: 20,
                }}
                onClick={handleClick}
              >
                <Typography variant="h5">
                  Services
                </Typography>
              </Link>
              <Link
                to="/gallery"
                className="noselect"
                style={{
                  textDecoration: "none",
                  color: activePathName === "Gallery" ? theme.palette.text.secondary : theme.palette.text.light,
                  marginTop: 20,
                }}
                onClick={handleClick}
              >
                <Typography variant="h5" style={{ textDecoration: "none" }}>
                  Gallery
                </Typography>
              </Link>
              <Link
                to="/about"
                className="noselect"
                style={{
                  textDecoration: "none",
                  color: activePathName === "About" ? theme.palette.text.secondary : theme.palette.text.light,
                  marginTop: 20,
                }}
                onClick={handleClick}
              >
                <Typography variant="h5" style={{ textDecoration: "none" }}>
                  About
                </Typography>
              </Link>
              <Link
                to="/contact"
                className="noselect"
                style={{
                  textDecoration: "none",
                  color: activePathName === "Contact Us" ? theme.palette.text.secondary : theme.palette.text.light,
                  marginTop: 20,
                }}
                onClick={handleClick}
              >
                <Typography variant="h5" style={{ textDecoration: "none" }}>
                  Contact Us
                </Typography>
              </Link>
            </Box>
            <Divider />
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "90vw",
                height: "10%",
                display: "flex",
                padding: 10,
                flexDirection: "row",
                backgroundColor: theme.palette.background.main,
              }}
            >
              <Button
                onClick={openFacebookPage}
                className="noselect"
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.light,
                }}
              >
                <FacebookIcon fontSize="large" />
              </Button>
              <Button
                onClick={openMessenger}
                className="noselect"
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.light,
                  marginLeft: 10,
                }}
              >
                 <Icon path={MessengerIcon} size={1.5} />
              </Button>
              <Button
                onClick={openEmailTo}
                className="noselect"
                variant="outlined"
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.light,
                  marginLeft: 10,
                }}
              >
                <EmailIcon fontSize="large" />
              </Button>

              <Button
                onClick={openInstagramPage}
                className="noselect"
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.light,
                  marginLeft: 10,
                }}
              >
                <InstagramIcon fontSize="large" />
              </Button>
            </Box>
          </Drawer>
          <Modal open={contactUsState} onClose={toggleContactForm}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                bgcolor: "background.paper",
                border: "2px solid #000",
                display: "flex",
              }}
            >
              <ContactPage
                closeContactForm={closeContactForm}
                openSuccessMessage={openSuccessMessage}
              />
            </Box>
          </Modal>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

// const useStyles = makeStyles((theme) => ({
//   // This group of buttons will be aligned to the right
//   rightToolbar: {
//     marginLeft: "auto",
//     marginRight: -12,
//   },
//   menuButton: {
//     display: "none",
//     marginRight: 16,
//     marginLeft: -12,
//     [theme.breakpoints.down("sm")]: {
//       display: "flex",
//     },
//   },
//   linkContainer: {
//     display: "flex",
//     marginLeft: 50,
//     height: "100%",
//     [theme.breakpoints.down("sm")]: {
//       display: "none",
//     },
//   },
//   mobileCurrentLink: {
//     display: "none",
//     marginLeft: 50,
//     [theme.breakpoints.down("sm")]: {
//       display: "flex",
//     },
//   },
// }));

export default Header;
