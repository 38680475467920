import React from "react";
import { Box, Divider, Typography, Button } from "@mui/material";
import { theme } from "../services/Theme";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function Footer() {
  const openInstagramPage = () => {
    window.open("https://www.instagram.com/haasfab_llc/", "_blank");
  };
  const openFacebookPage = () => {
    window.open("https://www.facebook.com/HaasFab-100925839159371", "_blank");
  };
  const openEmailTo = () => {
    window.open("mailto:business@haasfab.com", "_self");
  };

  const getYear = () => {
    let date = new Date();
    date = date.getFullYear();
    return date;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",

        backgroundColor: theme.palette.background.main,
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "50px",
          backgroundColor: theme.palette.background.main,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={openFacebookPage}
          className="noselect"
          style={{
            textDecoration: "none",
            color: theme.palette.text.light,
          }}
        >
          <FacebookIcon />
        </Button>

        <Button
          onClick={openEmailTo}
          className="noselect"
          variant="outlined"
          style={{
            textDecoration: "none",
            color: theme.palette.text.light,
            marginLeft: 20,
          }}
        >
          Business@HaasFab.com
        </Button>

        <Button
          onClick={openInstagramPage}
          className="noselect"
          style={{
            textDecoration: "none",
            color: theme.palette.text.light,
            marginLeft: 20,
          }}
        >
          <InstagramIcon />
        </Button>
      </Box>
      <Divider width="95%" sx={{ maxWidth: "1000px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "50px",
          backgroundColor: theme.palette.background.main,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography color={theme.palette.text.light}>
          © Copyright - HaasFab, LLC {getYear()}
        </Typography>
        <Link
          to="/privacy-policy"
          className="noselect"
          style={{
            textDecoration: "none",
            color: theme.palette.text.light,
            marginLeft: 10,
          }}
        >
          <Typography>Privacy Policy</Typography>
        </Link>
      </Box>
    </Box>
  );
}

export default Footer;
