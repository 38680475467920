/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomerMessage = /* GraphQL */ `
  mutation CreateCustomerMessage(
    $input: CreateCustomerMessageInput!
    $condition: ModelCustomerMessageConditionInput
  ) {
    createCustomerMessage(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerMessage = /* GraphQL */ `
  mutation UpdateCustomerMessage(
    $input: UpdateCustomerMessageInput!
    $condition: ModelCustomerMessageConditionInput
  ) {
    updateCustomerMessage(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerMessage = /* GraphQL */ `
  mutation DeleteCustomerMessage(
    $input: DeleteCustomerMessageInput!
    $condition: ModelCustomerMessageConditionInput
  ) {
    deleteCustomerMessage(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      createdAt
      updatedAt
    }
  }
`;
